#gallery {
    --THUMBNAILS_HEIGHT: 10em;
    --THUMBNAILS_CLAMP: clamp(var(--THUMBNAILS_HEIGHT), 15em, 10vh);
    --MAIN_CLAMP: clamp(20em, 60em, 70vh);
    @media (max-width: 1200px) {
        --THUMBNAILS_HEIGHT: 8em;
        --THUMBNAILS_CLAMP: clamp(var(--THUMBNAILS_HEIGHT), 12em, 10vh);
        --MAIN_CLAMP: clamp(20em, 60em, 42vh);
    }
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--MAIN_CLAMP) var(--THUMBNAILS_HEIGHT);
    grid-template-areas:
        "main"
        "thumbnails";
    row-gap: 0.9em;
    min-height: 100%;
    &.empty-gallery {
        grid-template-rows: auto;
        grid-template-areas: "main";
    }

    .main-img {
        grid-area: main;
        @extend .img-container;
        display: flex;
        &:hover {
            cursor: pointer;
        }
        #current {
            object-fit: scale-down;
        }
    }
    .thumbnails {
        grid-area: thumbnails;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: var(--THUMBNAILS_CLAMP);
        grid-template-columns: var(--THUMBNAILS_CLAMP);
        grid-template-rows: var(--THUMBNAILS_CLAMP);
        overflow-x: scroll;
        column-gap: 0.9em;
        .thumbnail-img {
            @extend .img-container;
            img {
                height: var(--THUMBNAILS_HEIGHT);
                object-fit: cover;
                object-position: center center;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }

    .lightbox-modal {
        display: none;
        position: fixed;
        z-index: 99999;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.591);
        &__close-btn {
            z-index: 999999;
            color: white;
            position: absolute;
            top: 10px;
            right: 25px;
            font-size: 35px;
            font-weight: bold;
            &:hover,
            &:focus {
                color: #999;
                text-decoration: none;
                cursor: pointer;
            }
        }
        &__content {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;
            padding: 0;
            width: clamp(300px, 100%, 1200px);
            height: 100%;
            .slide {
                display: none;
            }

            .image-slide {
                width: 100%;
            }
        }
        &__navigation {
            cursor: pointer;
            position: absolute;
            top: 50%;
            width: auto;
            padding: 16px;
            margin-top: -50px;
            color: white;
            font-weight: bold;
            font-size: 20px;
            transition: 0.6s ease;
            border-radius: 0 3px 3px 0;
            user-select: none;
            &--next {
                right: 0;
                border-radius: 3px 0 0 3px;
            }
            &:hover {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }
    }
}
