@use "sass:list";

.img-container {
    max-width: 100%;
    height: auto;
    img {
        resize: both;
        width: 100%;
        height: auto;
    }
}

.clean-ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        list-style-type: none;
        display: inline;
    }
}

@for $i from 1 through 5 {
    .line-clamp-#{$i} {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $i;
        overflow: hidden;
    }
}

.medium {
    font-size: 90%;
}

$cursors: pointer, help, default;
@each $cursor in $cursors {
    .hover-#{$cursor} {
        &:hover {
            cursor: #{$cursor} !important;
        }
    }
}
