#vehiclesList {
    .content {
        .container-fluid {
            .vehicles-container {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(30em, 2fr));
                gap: 1.5em;
                @media (min-width: 600px) and (max-width: 1350px) {
                    grid-template-columns: repeat(auto-fill, minmax(23em, 2fr));
                }
                @media (min-width: 1350px) and (max-width: 1600px) {
                    grid-template-columns: repeat(auto-fill, minmax(24vw, 2fr));
                }
            }
            @include media-breakpoint-down(sm) {
                padding-left: 0;
                padding-right: 0;
                .vehicles-container {
                    grid-template-columns: 1fr;
                    gap: 1.5em;
                }
            }
        }
    }
}

%card-vehicle-title {
    $FONT_SIZE: 1.5rem;
    $LINE_HEIGHT: 1.2;
    font-weight: 700;
    font-size: $FONT_SIZE;
    line-height: $LINE_HEIGHT;
    height: calc(#{$FONT_SIZE} * 2 * #{$LINE_HEIGHT});
    border-bottom: solid 0.05em gray;
    padding-bottom: 20%;
    margin-bottom: 0.8em;
}

.vehicle-card-container {
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.302));
    gap: 1em;
    .vehicle-card {
        @extend .card;
        font-family: $font-family-brand;
        color: #221f20;
        background-color: white;
        clip-path: inset(0% 0% 0% 0% round 8px 8px 0 0);
        min-height: 100%;
        &__header {
            @extend .card-img-top;
            padding: 0;
            width: 100%;
            .img-container {
                height: 20em;
                a {
                    height: 20em;
                    display: block;
                    img {
                        object-fit: cover;
                        height: 100%;
                    }
                }
            }
        }
        &__body {
            @extend .card-body;
        }
        &__footer {
            @extend .card-footer;
            @extend .bg-white;
            @extend .border-0;
            padding: 0.8em 0.4em;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(1fr, 2);
            row-gap: 0.5em;
        }
    }
    .vehicle-card-body {
        &__title {
            @extend %card-vehicle-title;
        }
        &__list-container {
            height: 100%;
            .vehicle-card-details-list {
                @extend .list-group;
                height: 100%;
                li {
                    @extend .list-group-item;
                    @extend .px-0;
                    @extend .py-2;
                    @extend .border-0;
                    display: flex;
                    justify-content: space-between;
                    > div:first-child,
                    em {
                        width: 55%;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 0.875rem;
                        line-height: 115%;
                    }
                    em.total {
                        font-size: 120%;
                        text-align: right;
                    }
                    &:last-child {
                        @extend .pt-5;
                        @extend .pb-3;
                    }
                }
            }
            &--vendu {
                filter: opacity(0.3);
            }
        }
    }
    .vehicle-card-header-info {
        @extend .w-100;
        background-color: #ececec;
        font-family: $font-family-base;
        font-size: 17px;
        font-weight: 600;
        line-height: 3em;
        color: #343a40;
        display: flex;
        justify-content: space-evenly;
        div {
            &:before {
                padding-right: 0.5em;
            }
        }
        .stock-number {
            @include fa-icon-by-unicode("f63c", "solid");
        }
        .media-count {
            @include fa-icon-by-unicode("f030", "solid");
        }
    }
    .vehicle-card-footer {
        &__show-link {
            a {
                @extend .btn;
                @extend .btn-primary;
                @include button-size(0.8rem, 1rem, 0.8rem, 120%, 0.25rem);
                width: 100% !important;
            }
        }
        &__status-btn {
            @extend .btn;
            @extend .py-3;
            @include button-size(1.2rem, 1rem, 1.125rem, 120%, 0.25rem);
            font-family: $font-family-brand;
            text-transform: capitalize;
            &:before {
                padding-right: 0.3em;
            }
            &--disponible,
            &--usine {
                @include button-variant($teal, $teal, $teal);
                font-weight: 600;
            }
            &--disponible {
                @include fa-icon-by-unicode("f058", "solid");
            }
            &--usine {
                @include fa-icon-by-unicode("f3b3", "solid");
            }
            &--en_transit {
                @extend .btn-secondary;
                @include fa-icon-by-unicode("f017", "solid");
            }
            &--reserve {
                @extend .btn-warning;
                @include fa-icon-by-unicode("f017", "solid");
            }
            &--vendu {
                @include button-variant(
                    $red-infernal,
                    $red-infernal,
                    $red-infernal
                );
                @include fa-icon-by-unicode("f2e8", "solid");
            }
            &:hover {
                cursor: default !important;
            }
        }
    }
}

.vehicle-details-container {
    padding: 2em;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    @media (max-width: 1000px) {
        padding: 1.2em;
    }
    .vehicle-details {
        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 3em;
            align-items: flex-start;
            @media (max-width: 1200px) {
                display: grid;
                grid-template-columns: 1fr max-content 1fr;
            }
        }
        &__body {
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-template-rows: min-content auto auto;
            grid-template-areas:
                "gallery pricings"
                "description ."
                "reviews .";
            row-gap: 1.6em;
            column-gap: 1.6em;
            min-height: 100vh;
            width: clamp(200px, 100%, 1900px);
            margin: auto;
            @media (min-width: 1200px) and (max-width: 1700px) {
                grid-template-columns: 60% 40%;
                grid-template-areas:
                    "gallery pricings"
                    "description description"
                    "reviews reviews";
            }
            @media (max-width: 1200px) {
                grid-template-columns: 1fr;
                grid-template-rows: auto auto auto auto;
                grid-template-areas:
                    "mobile-title"
                    "gallery"
                    "pricings"
                    "description"
                    "reviews";
            }
        }
    }
    .vehicle-details-header {
        &__back-btn {
            display: flex;
            align-items: center;
            a {
                $BUTTON_COLOR: #595d62;
                @include button-size(0.9rem, 0.8rem, 0.8rem, 1rem, 0.25rem);
                @include button-variant($BUTTON_COLOR, $BUTTON_COLOR);
                @extend .text-uppercase;
                color: rgba(194, 199, 208);
                &:before {
                    padding-right: 0.4em;
                    content: "<";
                }
                @media (max-width: 700px) {
                    span {
                        display: none;
                    }
                    line-height: 0.2rem;
                }
            }
        }
        &__pagination {
            * {
                background-color: transparent !important;
            }
            li:nth-child(2) {
                @extend .disabled;
            }
            a {
                i {
                    display: none;
                }
                @media (max-width: 700px) {
                    span {
                        display: none;
                    }
                    i {
                        display: block;
                    }
                }
            }
            @media (max-width: 700px) {
                .page-item--count {
                    .page-link {
                        padding: 0.4rem 0.6rem;
                    }
                }
            }
        }
        &__visibility-switch {
            @extend %visibility-switch;
            @media (max-width: 1200px) {
                visibility: hidden;
            }
        }
    }
    .vehicle-details-body {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
        background-color: white;
        &__mobile-title {
            display: none;
            grid-area: mobile-title;
            @extend %card-vehicle-title;
            height: max-content;
            padding-bottom: unset;
            margin-bottom: unset;
            padding: 1.1rem;
            border-bottom: unset;
            background-color: #221f20;
            font-family: $font-family-brand;
            color: #e7e7e7;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
            @media (max-width: 1200px) {
                display: block;
            }
        }
        &__gallery {
            grid-area: gallery;
            box-shadow: unset;
            height: max-content;
        }
        &__pricings {
            grid-area: pricings;
            height: max-content;
        }
        &__descripion {
            grid-area: description;
            padding: 2em;
            @media (max-width: 700px) {
                padding: 1.2em;
            }
            min-height: 37em;
            .nav-pills {
                --gap: 1.1em;
                display: inline-flex !important;
                flex-wrap: wrap !important;
                margin: calc(-1 * var(--gap)) 0 1.9em calc(-1 * var(--gap)) !important;
                width: calc(100% + var(--gap)) !important;
                & > * {
                    margin: var(--gap) 0 0 var(--gap) !important;
                }
                a.nav-link {
                    font-size: 1.125rem;
                    line-height: 1.2;
                    padding: 0.85rem 1.5rem;
                    &:not(.active) {
                        background-color: #e7e7e7;
                        color: #221f20;
                    }
                }
                &--mobile {
                    display: none !important;
                    .dropdown-toggle::after {
                        display: none;
                    }
                    a.dropdown-toggle {
                        display: flex;
                        align-items: center;
                        padding: 0.85rem 1rem;
                        span {
                            margin-right: 0.5rem;
                        }
                        i {
                            margin-left: auto;
                        }
                    }
                    .show {
                        i {
                            transition: all 0.1s;
                            transform: rotate(180deg);
                        }
                    }
                }
                @media (max-width: 900px) {
                    display: none !important;
                    &--mobile {
                        display: inline-flex !important;
                    }
                }
            }
            .tab-content {
                font-family: $font-family-brand;
                > .active {
                    @extend .row;
                    @extend .mx-0;
                }
            }
            h4 {
                font-size: 20px;
                line-height: 24px;
                font-weight: 500;
            }
        }
        &__reviews {
            grid-area: reviews;
            background-color: white;
            padding: 2em;
            font-family: $font-family-brand;
            h3 {
                font-weight: 600;
                font-size: 1.375rem;
                line-height: 118%;
            }
        }
    }

    #pills-roush-factory-modification {
        .pills-roush-factory-modification {
            &__description {
                @extend .col-md-7;
                @extend .col-12;
            }
            &__details {
                @extend .col-md-5;
                @extend .col-12;
            }
            &__description {
                @extend .pl-0;
                @extend .pr-md-5;
                @extend .pb-md-1;
                @extend .pb-3;
            }
            &__details {
                @extend .pr-0;
                @extend .pl-0;
                @extend .pl-md-2;
                .list-group-item {
                    @extend .border-0;
                    padding: 0.2rem 0 !important;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    font-size: 16px;
                    line-height: 28px;
                    b {
                        font-weight: 500;
                        padding-right: 5%;
                    }
                    em {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
    #pills-post-factory-modification {
        .pills-post-factory-modification {
            &__single {
                @extend .col-md-5;
                @extend .col-12;
                @extend .pr-md-5;
                @extend .pb-4;
                @extend .pb-md-1;
            }
            &__groups {
                @extend .col-md-7;
                @extend .col-12;
                ul.list-group {
                    span.expand-group {
                        @extend .badge;
                        @extend .badge-pill;
                        @extend .hover-pointer;
                        &[aria-expanded="false"] {
                            display: block;
                            @extend .badge-success;
                            &:after {
                                content: "Ouvrir";
                            }
                        }
                        &[aria-expanded="true"] {
                            @extend .badge-secondary;
                            display: block;
                            &:after {
                                content: "Fermer";
                            }
                        }
                    }
                }
            }
        }
    }
}

.vehicle-details-pricings {
    display: flex;
    flex-direction: column;
    height: 100%;
    --CARD-DETAILS-PADDING: 2rem;
    @media (max-width: 700px) {
        --CARD-DETAILS-PADDING: 1.1rem;
    }

    &__header {
        @extend %card-vehicle-title;
        font-family: $font-family-brand;
        padding-bottom: unset;
        background-color: #221f20;
        padding: var(--CARD-DETAILS-PADDING);
        border-bottom: unset;
        color: #e7e7e7;
        height: unset;
        @media (max-width: 1200px) {
            padding: 0.7rem 1.1rem;
        }
        .vehicle-details-pricings-header {
            &__visibility-switch {
                @extend %visibility-switch;
                display: none;
            }
            @media (max-width: 1200px) {
                &__title {
                    display: none;
                }
                &__visibility-switch {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-size: 70%;
                }
            }
        }
    }
    &__body {
        padding: 0 var(--CARD-DETAILS-PADDING);
        .show-detailed-pricing {
            .card {
                box-shadow: unset;
                border: solid 0.1em #e7e7e7;
                & > .card-header {
                    display: none;
                }
                @media (max-width: 600px) {
                    border: unset;
                    .card-body,
                    .card-footer {
                        padding-left: 0;
                        padding-right: 0;
                    }
                    .dot-matrix-line:not(.dot-matrix-line--title) {
                        grid-template-columns: 100px 2fr 1fr 2fr 1fr;
                        .dot-matrix-line__title {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .show-client-pricing {
            @extend .py-4;
            &__total {
                display: flex;
                justify-content: space-between;
                font-family: $font-family-brand;
                color: #221f20;
                font-weight: 700;
                font-size: 1.56rem;
                @media (max-width: 700px) {
                    flex-direction: column;
                }
            }
        }
    }
    &__footer {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 var(--CARD-DETAILS-PADDING) var(--CARD-DETAILS-PADDING);
        font-family: $font-family-brand;
        .vehicle-details-pricings-footer {
            &__btn-information {
                @extend .btn;
                @extend .btn-primary;
                @include button-size(0.8rem, 1rem, 1.375rem, 120%, 0.25rem);
                @include fa-icon-by-unicode("f0e0", "solid");
                @extend .hover-default;
                width: 100% !important;
                font-weight: 500;
                &:before {
                    padding-right: 0.5em;
                }
                font-size: clamp(0.9rem, 1.4rem, 3vw);
            }
            &__contact-form {
                display: flex;
                flex-direction: column;
                .contact-form {
                    @extend .py-1;
                    display: flex;
                    font-size: 1rem;
                    line-height: 135%;
                    &__from,
                    &__subject {
                        span:first-child {
                            padding-right: 0.4em;
                        }
                        span:nth-child(2) {
                            font-weight: 500;
                        }
                    }
                    &__methods,
                    &__message {
                        @extend .form-group;
                        flex-wrap: wrap;
                        align-items: center;
                        label {
                            font-weight: 400;
                        }
                        @media (max-width: 700px) {
                            select {
                                width: 100% !important;
                            }
                        }
                    }
                    &__methods {
                        justify-content: space-between;
                        select {
                            width: clamp(220px, 60%, 75%);
                            background-color: #c2c7c8 !important;
                            color: white;
                            font-family: $font-family-brand;
                        }
                    }
                    &__submit {
                        > input[type="submit"] {
                            @extend .btn;
                            @extend .btn-secondary;
                            @extend .text-white;
                            @extend .w-100;
                            @include button-size(
                                0.8rem,
                                1rem,
                                1.375rem,
                                120%,
                                0.25rem
                            );
                        }
                    }
                }
            }
        }
        hr {
            border-bottom: solid 0.15em gray;
            border-top: 0;
        }
    }
}

.vehicle-review {
    @extend .px-3;
    @extend .py-2;
    @extend .my-4;
    @extend .rounded;
    background-color: rgba(231, 231, 231, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #03060d;
    &__body {
        @extend .py-3;
        font-size: 1.125rem;
        font-weight: 400;
        min-height: 5rem;
    }
    &__footer {
        @extend .pb-3;
        display: inline-flex;
        align-items: center;
        i {
            @extend .pr-1;
            color: #f7cc1a;
            font-size: 1.125rem;
        }
        .author {
            @extend .mx-2;
            display: inline-flex;
            align-items: center;
            font-size: 0.875rem;
            line-height: 114%;
            font-weight: 500;
            &__logo {
                @extend .img-container;
                height: 1.75rem;
                width: clamp(2rem, 4rem, 7rem);
            }
            &__name {
            }
        }
    }
}
