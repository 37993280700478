.page-header--parts {
    .page-header__title {
        h1 {
            display: flex;
            align-items: center;
        }
        i.custom-motor {
            padding: 0.2em;
            margin-right: 0.3em;
            width: 2em;
            &:before {
                content: url("/images/motor.svg");
                filter: invert(90%) sepia(12%) saturate(160%) hue-rotate(180deg)
                    brightness(89%) contrast(160%);
            }
        }
    }
}
