footer.footer-container {
    background-color: rgba(52, 58, 64, 0.5);
    border-top: unset;
    box-shadow: 0px 7px 61px 2px rgb(163, 158, 163);
    .footer-single-container {
        display: flex;
        justify-content: space-between;
        .button-save {
            color: white;
        }
        .btn-secondary {
            background-color: white;
        }
    }
    &--brands {
        .button-save {
            background-color: $color__brands;
        }
    }
    &--models {
        .button-save {
            background-color: $color__models;
        }
    }
    &--stock {
        .button-save {
            background-color: $color__stock;
        }
    }
    &--trims {
        .button-save {
            background-color: $color__trims;
        }
    }
    &--settings {
        .button-save {
            color: #ffffff;
            background-color: $color__primary--dark;
        }
    }
}
