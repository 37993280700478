.modal.access-request-modal {
    .modal-content {
        border-radius: 1.8rem;

        .modal-header {
            padding: 0;

            img {
                border-top-left-radius: 1.8rem;
                border-top-right-radius: 1.8rem;
            }
        }
        .modal-body {
            padding: 0;

            h2 {
                font-size: 1.2rem;
            }
        }
    }
}

.modal-dialog {
    transform: translate(0, -40%) !important;
    top: 40%;
    margin: 0 auto;
}

#roleDescriptionModal {
    .modal-body {
        h5 {
            font-weight: 500;
            i {
                font-size: 80%;
                padding-left: 0.2rem;
            }
        }
        p {
            margin-left: 4%;
        }
        ul {
            list-style-type: circle;
            li {
                font-style: italic;
                font-weight: 600;
            }
            p {
                margin-left: 0;
            }
        }
    }
}
