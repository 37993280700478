.table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    max-width: 95vw;

    a {
        color: inherit;
        &:hover {
            text-decoration: none;
        }
    }
    .table-header,
    .table-row {
        display: table-row;
        .table-cell,
        .table-head {
            display: table-cell;
        }
    }
    .table-header {
        .table-head {
            padding: 1em;
            font-weight: bold;
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
        }
    }
    .table-row {
        &:nth-of-type(2n + 1) {
            background-color: rgba(0, 0, 0, 0.05);
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.075);
            color: $blue-infernal-light;
        }
        .table-cell {
            padding: 0.75rem;
            vertical-align: top;
            border-top: 1px solid #dee2e6;
        }
    }
}
