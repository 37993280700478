$choices-selector: "choices" !default;
$choices-font-size-lg: 1rem !default;
$choices-font-size-md: 0.8rem !default;
$choices-font-size-sm: 0.7rem !default;
$choices-font-spacing-base: 0.2em !default;
$choices-guttering: 24px !default;
$choices-border-radius: 2.5px !default;
$choices-border-radius-item: 20px !default;
$choices-bg-color: #ffffff !default;
$choices-bg-color-disabled: #eaeaea !default;
$choices-bg-color-dropdown: #ffffff !default;
$choices-text-color: #333333 !default;
$choices-keyline-color: #dddddd !default;
$choices-primary-color: $color__primary !default;
$choices-disabled-color: #eaeaea !default;
$choices-highlight-color: $choices-primary-color !default;
$choices-button-dimension: 8px !default;
$choices-button-offset: 8px !default;
$choices-icon-cross: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==) !default;
$choices-icon-cross-inverse: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==) !default;
$choices-z-index: 1;

.#{$choices-selector} {
    position: relative;
    overflow: hidden;
    margin-bottom: $choices-guttering;
    font-size: $choices-font-size-lg;
    &:focus {
        outline: none;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &.is-open {
        overflow: initial;
    }
    &.is-disabled {
        .#{$choices-selector}__inner,
        .#{$choices-selector}__input {
            background-color: $choices-bg-color-disabled;
            cursor: not-allowed;
            user-select: none;
        }
        .#{$choices-selector}__item {
            cursor: not-allowed;
        }
    }

    [hidden] {
        display: none !important;
    }
}

.#{$choices-selector}[data-type*="select-one"] {
    cursor: pointer;
    .#{$choices-selector}__inner {
        padding-bottom: $choices-font-spacing-base;
    }
    .#{$choices-selector}__input {
        display: block;
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid $choices-keyline-color;
        background-color: #ffffff;
        margin: 0;
    }
    .#{$choices-selector}__button {
        background-image: $choices-icon-cross-inverse;
        padding: 0;
        background-size: 8px;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
        margin-right: 25px;
        height: 20px;
        width: 20px;
        border-radius: 10em;
        opacity: 0.25;
        &:hover,
        &:focus {
            opacity: 1;
        }
        &:focus {
            box-shadow: 0px 0px 0px 2px $choices-highlight-color;
        }
    }
    .#{$choices-selector}__item[data-value=""] .#{$choices-selector}__button {
        display: none;
    }
    &.is-open:after {
        border-color: transparent transparent $choices-text-color transparent;
        margin-top: -#{$choices-font-spacing-base};
    }
    &[dir="rtl"] {
        &:after {
            left: 11.5px;
            right: auto;
        }
        .#{$choices-selector}__button {
            right: auto;
            left: 0;
            margin-left: 25px;
            margin-right: 0;
        }
    }
}

.#{$choices-selector}[data-type*="select-multiple"],
.#{$choices-selector}[data-type*="text"] {
    .#{$choices-selector}__inner {
        cursor: text;
    }
    .#{$choices-selector}__button {
        position: relative;
        display: inline-block;
        margin-top: 0;
        margin-right: -$choices-button-offset/2;
        margin-bottom: 0;
        margin-left: $choices-button-offset;
        padding-left: $choices-button-offset * 2;
        border-left: 1px solid darken($choices-primary-color, 10%);
        background-image: $choices-icon-cross;
        background-size: $choices-button-dimension;
        width: $choices-button-dimension;
        line-height: 1;
        opacity: 0.75;
        border-radius: 0;
        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}

.#{$choices-selector}__inner {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: $choices-bg-color;
    padding: $choices-font-spacing-base $choices-font-spacing-base
        calc(#{$choices-font-spacing-base} / 2);
    border: 1px solid $choices-keyline-color;
    border-radius: $choices-border-radius;
    font-size: $choices-font-size-md;
    // min-height: 44px;
    overflow: hidden;
    .is-focused &,
    .is-open & {
        border-color: darken($choices-keyline-color, 15%);
    }
    .is-open & {
        border-radius: $choices-border-radius $choices-border-radius 0 0;
    }
    .is-flipped.is-open & {
        border-radius: 0 0 $choices-border-radius $choices-border-radius;
    }
}

.#{$choices-selector}__list {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.#{$choices-selector}__list--single {
    display: inline-block;
    padding: $choices-font-spacing-base;
    padding-right: calc(#{$choices-font-spacing-base} * 4);
    width: 100%;
    padding-left: calc(#{$choices-font-spacing-base} * 4);
    width: 100%;
    background-color: rgb(255, 255, 255);
    background-position: center right 0.75rem;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
    background-size: 8px 10px;
    background-origin: padding-box;
    background-clip: border-box;
    [dir="rtl"] & {
        padding-right: $choices-font-spacing-base;
        padding-left: calc(#{$choices-font-spacing-base} * 4);
    }
    .#{$choices-selector}__item {
        width: 100%;
    }
}

.#{$choices-selector}__list--multiple {
    display: inline;
    .#{$choices-selector}__item {
        display: inline-block;
        vertical-align: middle;
        border-radius: $choices-border-radius-item;
        padding: 4px 10px;
        font-size: $choices-font-size-sm;
        font-weight: 500;
        margin-right: 3.75px;
        margin-bottom: 3.75px;
        background-color: $choices-primary-color;
        border: 1px solid darken($choices-primary-color, 5%);
        color: #ffffff;
        word-break: break-all;
        box-sizing: border-box;
        &[data-deletable] {
            padding-right: 5px;
        }
        [dir="rtl"] & {
            margin-right: 0;
            margin-left: 3.75px;
        }
        &.is-highlighted {
            background-color: darken($choices-primary-color, 5%);
            border: 1px solid darken($choices-primary-color, 10%);
        }
        .is-disabled & {
            background-color: darken($choices-disabled-color, 25%);
            border: 1px solid darken($choices-disabled-color, 35%);
        }
    }
}

.#{$choices-selector}__list--dropdown {
    visibility: hidden;
    z-index: $choices-z-index;
    position: absolute;
    width: 100%;
    background-color: $choices-bg-color-dropdown;
    border: 1px solid $choices-keyline-color;
    top: 100%;
    margin-top: -1px;
    border-bottom-left-radius: $choices-border-radius;
    border-bottom-right-radius: $choices-border-radius;
    overflow: hidden;
    word-break: break-all;
    will-change: visibility;
    box-shadow: 0.1rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
    &.is-active {
        visibility: visible;
    }
    .is-open & {
        border-color: $choices-keyline-color;
    }
    .is-flipped & {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: -1px;
        border-radius: 0.25rem 0.25rem 0 0;
    }
    .#{$choices-selector}__list {
        position: relative;
        max-height: 300px;
        overflow: auto;
        will-change: scroll-position;
    }
    .#{$choices-selector}__item {
        position: relative;
        padding: 10px;
        font-size: $choices-font-size-md;
        [dir="rtl"] & {
            text-align: right;
        }
    }
    .#{$choices-selector}__item--selectable {
        @media (min-width: 640px) {
            padding-right: 100px;
            &:after {
                content: attr(data-select-text);
                font-size: $choices-font-size-sm;
                opacity: 0;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
            [dir="rtl"] & {
                text-align: right;
                padding-left: 100px;
                padding-right: 10px;
                &:after {
                    right: auto;
                    left: 10px;
                }
            }
        }
        &.is-highlighted {
            background-color: mix(#000000, #ffffff, 5%);
            &:after {
                opacity: 0.5;
            }
        }
    }
}

.#{$choices-selector}__item {
    cursor: default;
    &:not(.choices__placeholder) {
        &.is-selected {
            background-color: $blue-infernal !important;
            color: #ffffff !important;
        }
    }
}

.#{$choices-selector}__item--selectable {
    cursor: pointer;
}

.#{$choices-selector}__item--disabled {
    cursor: not-allowed;
    user-select: none;
    opacity: 0.5;
}

.#{$choices-selector}__heading {
    font-weight: 600;
    font-size: $choices-font-size-sm;
    padding: 10px;
    border-bottom: 1px solid lighten($choices-keyline-color, 10%);
    color: lighten(#333, 30%);
}

.#{$choices-selector}__button {
    text-indent: -9999px;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

.#{$choices-selector}__input {
    display: inline-block;
    vertical-align: baseline;
    background-color: $choices-bg-color;
    font-size: $choices-font-size-md;
    margin-bottom: 5px;
    border: 0;
    border-radius: 0;
    max-width: 100%;
    padding: 4px 0 4px 2px;
    &:focus {
        outline: 0;
    }
    [dir="rtl"] & {
        padding-right: 2px;
        padding-left: 0;
    }
}

.#{$choices-selector}__placeholder {
    opacity: 0.5;
}
