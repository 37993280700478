@use "sass:map";

.grecaptcha-badge {
    visibility: hidden !important;
}

.badge {
    border-radius: 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    padding: 0.375rem 0.5625rem;
    vertical-align: middle;
}

.badge.badge-pill {
    border-radius: 10rem;
}

.badge-custom-light {
    color: $red-infernal;
    background-color: #ffffff;
}

.badge-status {
    display: inline-block;
    border-radius: 5px;
    color: #ffffff;
    font-weight: normal;
    padding: 0.375rem 1rem;
    margin: 0.1rem;
}

$stockStatuses: (
    "disponible": $green-infernal,
    "usine": $green-infernal,
    "en_transit": $gray,
    "reserve": $orange-infernal,
    "vendu": $red,
    "archive": $gray-medium
);

.badge {
    @each $status, $color in $stockStatuses {
        &.badge-#{$status} {
            background-color: $color;
        }
    }
}
