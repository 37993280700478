.card {
    &.card-default {
        .card-header {
            background-color: #555;
            color: $color__light;
            text-align: center;
        }
    }
}

@mixin negativeValueBracket($side) {
    .dot-matrix-line__price--#{$side}:not(.decimal) {
        &:before {
            content: "(";
        }
    }
    .dot-matrix-line__price--#{$side}.decimal {
        &:after {
            content: ")";
        }
    }
}
.list-group--dot-matrix-printer,
.card-footer--dot-matrix-printer {
    font-family: $font-family-monospace;
    font-weight: bold;
    .dot-matrix-line {
        display: grid;
        grid-template-columns: 3fr 2fr 1fr 2fr 1fr;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
        &__title {
        }
        &__price {
            text-align: right;
            border-right: dashed 2px black;
            padding-right: 0.4em;
            &.decimal {
                text-align: left;
                border-right: none;
                padding-left: 0.4em;
            }
        }
        &--title {
            grid-template-columns: 3fr 2fr 2fr;
            padding-bottom: 0.8em;
            .dot-matrix-line {
                &__price {
                    text-align: center;
                    border-right: none;
                }
            }
        }
        &--feeHoldback {
            @include negativeValueBracket("left");
        }
    }
}

.box-profile .list-group-item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;

    i {
        align-self: center;
    }
}
