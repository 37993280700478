div.dataTables_wrapper {
    a {
        &:link {
            text-decoration: underline;
        }

        &:hover {
            text-decoration: none;
        }
    }

    button.btn-link {
        color: $gray-900;

        &:hover {
            color: #006890;
        }
    }
    div.dataTables_length {
        margin: auto 0;
    }

    div.dataTables_info {
        margin: auto 0;
        padding: 0;
    }

    .thumbnail {
        position: relative;
        width: 133px;
        height: 100px;
        overflow: hidden;
        margin: 0 auto;
    }
    .thumbnail img {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .thumbnail img.portrait {
        width: 100%;
        height: auto;
    }

    .table-align-middle > tbody > tr > td {
        vertical-align: middle;

        &:first-child {
            padding: 0.3rem 0 !important;
        }
    }
    .processing {
        height: 20vh !important;
        position: absolute;
        width: 100%;
    }
    .table--parts {
        tbody > tr > td {
            vertical-align: middle;
        }
        thead {
            th {
                padding-bottom: 0.3em;
                &::before,
                &::after {
                    bottom: 0.3em;
                }
            }
            .hidden-dealer-price {
                color: $red-infernal;
            }
        }
        td.hidden-dealer-price {
            background-color: $red-infernal-lighter;
        }
    }
    #total {
        display: flex;
        align-items: center;
        color: $color__primary;
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 1.6em;
    }
}

.stock-datatables-wrapper {
    .card-body {
        margin-top: -1rem;
        .dealer-price-filter-wrapper,
        .archives-filter-wrapper {
            position: relative;
            top: 25px;
            width: 50%;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
        div.dataTables_filter {
            @include media-breakpoint-down(md) {
                text-align: right !important;
            }
            @include media-breakpoint-down(sm) {
                margin-top: 2.6em;
            }
        }
    }
}
.parts-list-filters {
    display: flex;
    &__dropdowns {
        flex: 1 1 auto;
        .list-filters-container {
            display: grid;
            grid-template-columns: 5.5em auto;
            .filters-label {
                margin-right: 0.5rem;
                font-weight: 700;
                align-self: center;
            }
            .filters-dropdowns {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(10em, 1fr)) 1em;
                .choices {
                    margin-bottom: 0;
                    @extend .mr-2;
                    .choices__item--selectable {
                        padding-right: 1%;
                        &::after {
                            display: none;
                        }
                    }
                }
                .reset-filters-button-wrapper {
                    button {
                        @extend .btn;
                        @extend .btn-outline-secondary;
                        @extend .btn-sm;
                        @extend .text-dark;
                        padding: 0.6em;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 2em auto 2em;
                .filters-label {
                    align-self: start;
                }
                .filters-dropdowns {
                    grid-template-columns: 1fr;
                    row-gap: 1em;
                }
                .reset-filters-button-wrapper {
                    button {
                        padding: 2%;
                    }
                }
            }
        }
    }
    &__visibility-switch {
        @extend %visibility-switch;
    }
}

td.dealerships-col {
    &__name {
        display: grid;
        grid-template-columns: 2em auto;
        column-gap: 1em;
        align-items: center;
    }
}

//Responsive datatable
.dataTable.collapsed > tbody {
    td {
        vertical-align: middle;
    }
    > tr {
        td.dtr-control {
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            &:before {
                top: 50%;
                left: 5px;
                height: 1em;
                width: 1em;
                margin-top: -9px;
                display: block;
                position: absolute;
                color: white;
                border: 0.15em solid white;
                border-radius: 1em;
                box-shadow: 0 0 0.2em #444;
                box-sizing: content-box;
                text-align: center;
                text-indent: 0 !important;
                font-family: "Courier New", Courier, monospace;
                line-height: 1em;
                content: "+";
                background-color: $color__primary;
            }
        }
        &.parent {
            td.dtr-control {
                &:before {
                    content: "-";
                    background-color: $red-infernal;
                }
            }
        }
    }
    td.child {
        ul.dtr-details {
            width: 100%;
            li {
                display: flex;
                width: 100%;
                justify-content: space-between;
                flex-wrap: wrap;
                .dtr-title {
                    text-align: left;
                }
                .dtr-data {
                    text-align: right;
                }
            }
        }
    }
}
