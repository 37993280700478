%visibility-switch {
    display: flex;
    i {
        color: rgba(194, 199, 208);
        padding-right: 0.7em;
        padding-top: 0.25rem;
    }
    .switch-wrapper {
        @extend .custom-control;
        @extend .custom-switch;
        @extend .custom-switch-on-danger;
        input {
            @extend .custom-control-input;
        }
        label {
            @extend .custom-control-label;
        }
    }
}
