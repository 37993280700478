.page-title {
    display: flex;
    @extend .mb-2;
    h1 {
        flex: 1 1 auto;
    }
}

.page-header {
    display: flex;
    color: white;

    &__options {
        .btn {
            color: white;
            font-size: 2em;
            padding-left: 0.5em;
            padding-right: 0.5em;
            border-color: transparent;
            border-radius: 0.5rem;
            i {
                margin: auto;
            }
        }
    }
    &__title {
        flex: 1 1 auto;
        display: inline-flex;
        align-items: center;
        @media (max-width: 1200px) {
            display: flex;
            flex-wrap: wrap;
            h1 {
                width: 100%;
            }
        }
        .badge {
            border-radius: 0.3rem !important;
        }
    }
    .button-close {
        background-color: transparent;
        color: white;
        font-size: 1.6rem;
        border: unset;
    }

    .page-header__options {
        .btn {
            background-color: $color__stock;
            &:hover {
                background-color: darken($color__stock, 5%);
            }
        }
    }
    .page-header__badge {
        background-color: #f8f9fa;
        border-radius: 0.25rem;
        color: #343a40;
        font-size: 80%;
        font-weight: 400;
        margin-left: 0.5rem;
        padding: 0 0.25rem;
    }
}

.content-wrapper {
    > .content-header {
        margin-bottom: 1rem;
        background-color: $color__stock;

        h1 {
            font-size: 1.5rem;
        }
    }
}
