@use "sass:map";

@mixin fa-icon-by-unicode($code, $weight:'normal',$family: "Font Awesome 5 Pro") {
    $font-weights: ("solid": 900, "normal": 400, "light": 300);
    &:before {
        font-family: $family;
        font-weight: map.get($font-weights, $weight);
        content: #{'"\\' + $code + '"'};
    }
}
