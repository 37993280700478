@use "sass:map";

.main-header {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
    border-bottom: unset;
}

.main-sidebar {
    .badge {
        border-radius: 0.3rem;
    }
    a.brand-link {
        img.brand-image {
            opacity: 1 !important;
            margin-left: 2.5rem;
            margin-right: 2.5rem;
        }

        img.logo-xs {
            left: 24px;
        }
    }
    .sidebar {
        overflow-y: unset;
        padding: 2.5rem 0.5rem 0.5rem 0.5rem;

        .nav-treeview a.nav-link {
            padding-left: 2rem;
            transition: padding-left 0.3s linear, opacity 0.3s ease,
                visibility 0.3s ease;
            p {
                line-height: 200%;
                span.right {
                    top: unset;
                }
            }
            &--inventory {
                display: grid;
                grid-template-columns: 3rem auto;
                align-items: center;
                p {
                    display: flex;
                    justify-content: space-between;
                    .right.badge {
                        position: unset;
                    }
                }
            }
        }
    }
}

.sidebar-collapse {
    .main-sidebar {
        a.brand-link {
            img.brand-image {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .nav-sidebar {
            .nav-treeview {
                .nav-link {
                    padding-left: 1rem;
                }
            }
        }
    }
}

.sidebar-dark-primary {
    .nav-sidebar {
        > .nav-item {
            > .nav-link {
                &.active {
                    background-color: $color__primary--dark;
                }
                .right {
                    transition: transform ease-in-out 0.1s;
                }
            }
        }
        > .nav-item:not(.has-treeview) {
            > .nav-link {
                p {
                    line-height: 200%;
                    .badge-bison-hide.right {
                        top: unset;
                    }
                }
            }
        }
        .menu-open {
            > .nav-link {
                i {
                    &.right {
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
}

nav.main-header.navbar.navbar-expand.navbar-dark.navbar-light
    ul.navbar-nav.ml-auto {
    .dealership-menu,
    .dealership-menu a {
        font-family: $font-family-base;
        font-size: 0.8rem;
        line-height: 1.6;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.75);
        &:hover {
            color: rgba(255, 255, 255, 1);
        }
    }
    .dealership-image {
        height: 1.8rem;
        width: auto;
        margin-top: -0.2rem;
    }

    .vertical-pipe {
        @extend .my-auto;
        height: 1.8rem;
        div {
            border-left: 0.2em solid rgba(255, 255, 255, 0.75);
            height: 100%;
        }
    }
    .dropdown.user-menu {
        ul.dropdown-menu {
            background-color: $background-menu;
            top: 48px !important;
            li,
            a,
            .btn {
                background-color: $background-menu !important;
                color: $font-menu !important;
            }
        }
    }
}
.inventory-card-menu {
    @extend .pb-3;
    display: flex;
    justify-content: space-between;
    &__nav {
        @extend .nav;
        @extend .nav-pills;
        .nav-item {
            margin: 0 0.5rem;
            background: #efefef;
            color: #000;
            &:first-child {
                margin-left: 0 !important;
            }
            .nav-link {
                position: relative;
                &::not(.active):hover {
                    color: $blue-infernal;
                }
            }
            .badge {
                @extend .custom-badge;
                position: absolute;
                right: -9px;
                top: -8px;
            }
        }
        @media (max-width: 1100px) {
            display: none;
        }

        &--mobile {
            display: none;
            @media (max-width: 1100px) {
                display: block;
            }
            width: clamp(13em, 18em, 80vw);
            .dropdown-toggle::after {
                display: none;
            }
            .dropdown-toggle {
                display: flex;
                justify-content: space-between;
            }
            .dropdown-menu {
                a.dropdown-item {
                    display: grid;
                    grid-template-columns: 2em auto 2em;
                }
            }
            .dropdown-item--inventory-count {
                @extend .badge;
                @extend .badge-secondary;
                @extend .text-white;
            }
        }
    }
    &__options {
        text-align: right;
    }
}

.custom-badge {
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    background-color: #888 !important;
    border: 2px solid white;
    border-radius: 0.5em;
    padding: 0.25em 0.5em;
}

$inventory-icons: (
    "f150": "f",
    "mustang": "m",
    "super-duty": "s",
    "ranger": "r"
);

.nav-inventory {
    font-family: $font-family-base;
    font-size: 1.2rem;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    transform: scale(0.8);
    &:before {
        border: solid 0.2em #c2c7d0;
        border-radius: 18%;
        display: inline-block;
        height: 2.5rem;
        line-height: 175%;
        vertical-align: middle;
        width: 2.5rem;
    }
    @each $inventory, $icon in $inventory-icons {
        &--#{$inventory} {
            &:before {
                content: $icon;
            }
        }
    }
}

.sidebar-mini.sidebar-collapse {
    aside.main-sidebar.sidebar-dark-primary {
        a.nav-link.nav-link--inventory {
            display: grid;
            grid-template-columns: 3rem auto;
            align-items: center;
            i {
                transform: translateX(-17%) scale(0.6);
            }
            p {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .right.badge {
                    position: unset;
                }
            }
        }
    }
}

.nav-item--custom-icon {
    display: flex;
    align-items: center;
}
.nav-icon.custom-motor {
    display: block;
    padding: 0.2em;
    margin-right: 0.3em;
    &:before {
        content: url("icons/motor.svg");
        filter: invert(90%) sepia(12%) saturate(160%) hue-rotate(180deg)
            brightness(89%) contrast(88%);
    }
}
