.statistics-cards {
    width: 100%;
    @extend .clean-ul;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    grid-gap: 1rem;
    ul {
        #stock-breakdown-by-brand {
            grid-area: stat-1;
        }
        #stock-breakdown-by-type {
            grid-area: stat-2;
        }
        #most-viewed-stock {
            grid-area: stat-3;
        }
        #older-stock {
            grid-area: stat-4;
        }
        #last-updated-stock {
            grid-area: stat-5;
        }
        #last-added-stock {
            grid-area: stat-6;
        }
    }

    canvas {
        padding: 1em;
    }

    @media (min-width: 2200px) {
        grid-template-columns: repeat(auto-fill, minmax(25vw, 1fr));
    }

    @media (max-width: 520px) {
        grid-template-columns: 1fr;
        max-width: 98vw !important;
        .img-container--chart {
            position: relative;
            max-width: 75vw !important;
        }
    }
}

.statistics-small-cards {
    width: 100%;
    @extend .clean-ul;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    grid-gap: 0.7rem;
}
