.checkbox-label-equipment {
    input[type="checkbox"]:checked ~ label::before {
        background: $blue-infernal !important;
    }
}
.card-columns.stock {
    column-count: 2 !important;
}
#equipments-assigned {
    column-count: 3;
    @media (min-width: 600px) and (max-width: 1100px) {
        column-count: 2;
    }
    @media (max-width: 600px) {
        column-count: 1;
    }
}
.checkbox-label-equipment {
    input[type="checkbox"] {
        cursor: pointer;
    }
}
ul.equipment-list.list-group {
    background: $blue-infernal !important;
}
.stock.checkbox-label-equipment {
    display: flex;
    width: 100%;
    button.btn-checkbox {
        &:before {
            content: "\f111";
            font-family: "Font Awesome 5 Pro";
            font-weight: lighter;
        }
        &.check:before {
            content: "\f058";
            font-family: "Font Awesome 5 Pro";
            font-weight: lighter;
            color: $green;
        }
        &.cross:before {
            content: "\f057";
            font-family: "Font Awesome 5 Pro";
            font-weight: lighter;
            color: $orange;
        }
    }
    .equipment-name-container {
        display: flex;
        flex-direction: row;
        .equipment-name-title,
        .equipment-name-tag {
            margin: auto 5px;
            small {
                border-radius: 3px;
                background-color: grey;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 8px;
                padding: 2px 3px;
                color: white;
            }
        }
    }
}
