// Body
$body-bg: #f8fafc;

$blue-infernal-light: #3eaeda;
$blue-infernal: #009fdc;
$blue-infernal-dark: #0289be;
$red-infernal: #bd1b21;
$red-infernal-lighter: #fcf4f5;
$orange-infernal: #f7941e;
$green-infernal: #28a745;

$gray-light: rgb(240, 240, 240);
$gray: #bebebe;
$gray-medium: #6e6e6e;
$gray-dark: #343a40;

// Body
$body-bg: #f8fafc;

$color__primary: $blue-infernal;
$color__primary--dark: $red-infernal;
$color__primary--light: $blue-infernal-light;
$color__secondary: $blue-infernal;
$color__ternary: #28a745;
$color__light: #f8f9fa;

$color__comp--prim: #f7941e;
$color__comp--sec: #c2c7c8;

$background-menu: $gray-dark;
$font-menu: #c2c7d0;

$colors: (
    "blue": $color__secondary,
    "indigo": $color__primary,
    "purple": #6f42c1,
    "pink": #e83e8c,
    "red": #dc3545,
    "orange": #fd7e14,
    "yellow": $color__comp--prim,
    "green": $color__ternary,
    "teal": #3fb94c,
    "cyan": #17a2b8,
    "white": #fff,
    "gray": $color__comp--sec,
    "gray-dark": $gray-dark
) !default;

$theme-colors: (
    "primary": $color__primary,
    "secondary": $color__comp--sec,
    "success": $color__ternary,
    "info": $color__secondary,
    "warning": $color__comp--prim,
    "danger": $red-infernal,
    "light": $gray-light,
    "dark": $gray-dark,
    "bison-hide": #afa38e
) !default;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
) !default;

// Typography
$font-family-sans-serif: "Source Sans Pro", sans-serif;
$font-family-base: "Source Sans Pro", "Helvetica", sans-serif;
$font-family-monospace: "Courier New", Courier, monospace;
$font-family-brand: "Ford Antenna", sans-serif;
$font-size-base: 0.8rem;
$line-height-base: 1.6;
$font-menu: #c2c7d0;

$color__stock: $red-infernal;
$color__trims: $blue-infernal;
$color__models: $orange-infernal;
$color__brands: $green-infernal;

$custom-file-text: (
    fr: "Parcourir"
);
